import * as styles from './contact.module.scss';

import React, { useState } from 'react';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPhone, faUserHeadset } from '@fortawesome/pro-regular-svg-icons';

import Button from '../components/button';
import Callout from '../components/v2/callout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Section from '../components/section';

const ContactPage = () => {
  return (
    <>
      <SEO
        title="Contact Us"
        description="Get expert support and guidance for your interactive marketing needs. Interact's dedicated team is ready to assist with product demos, technical support, and personalized solutions to elevate your quiz marketing strategy."
      />
      <Header btnTheme="primary" />
      <main className={styles.contact}>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Get in touch</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2 className="text-body color-muted-foreground text-center">
                Our team is here to help you with any questions or support you need.
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-4 text-center">
              <Link to="/demo/" style={{ textDecoration: 'none' }}>
                <div className={styles.card}>
                  <div className={styles.icon}>
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <div className={styles.content}>
                    <p className="heading-sm color-black">
                      <strong>Schedule a demo</strong>
                    </p>
                    <p className="text-body color-muted-foreground" style={{ fontSize: '14px' }}>
                      Our product demo will give you a comprehensive overview of how our platform
                      can streamline your workflow and boost productivity.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 text-center">
              <Link to="/plus/" style={{ textDecoration: 'none' }}>
                <div className={styles.card}>
                  <div className={styles.icon}>
                    <FontAwesomeIcon icon={faUserHeadset} />
                  </div>
                  <div className={styles.content}>
                    <p className="heading-sm color-black">
                      <strong>Work with an expert</strong>
                    </p>
                    <p className="text-body color-muted-foreground" style={{ fontSize: '14px' }}>
                      Work directly with a quiz expert from Interact to create and promote quizzes
                      for your business.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginTop: '5rem' }}>
            <div className="col-12 col-xl-9">
              <FAQCard title={`I want to see a product demo`}>
                <p className="text-body color-black">
                  We'd be thrilled to showcase our innovative solution! Our product demo will give
                  you a comprehensive overview of how our platform can streamline your workflow and
                  boost productivity.
                </p>
                <Button theme="primary" type="inernal" href="/demo/">
                  Schedule a demo
                </Button>
              </FAQCard>
              <FAQCard title={`I'm an existing customer and need support`}>
                <p className="text-body color-black">
                  We're here to help you with any questions or support you need. You can log in to
                  your Interact account and live chat with someone on our team!
                </p>
                <Button theme="primary" href={`${process.env.GATSBY_DASHBOARD_URL}/login`}>
                  Log in to chat
                </Button>
              </FAQCard>
              <FAQCard title={`General question / I just want to talk to someone at Interact`}>
                <p className="text-body color-black">
                  We're eager to assist you! Our team is dedicated to providing prompt and
                  professional support for all your inquiries.
                </p>
                <p className="text-body color-black">
                  Send us an email to <strong>contact@tryinteract.com</strong>.
                </p>
              </FAQCard>
            </div>
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

const FAQCard = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button className={styles.faqBtn} onClick={() => setOpen(!open)}>
        <span>{props.title}</span>
        {!open ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus} />}
      </button>
      <div className={[styles.collapse, open ? styles.show : ''].join(' ')}>
        <div className={styles.content}>{props.children}</div>
      </div>
    </>
  );
};

export default ContactPage;
